<script>
	import { setContext } from 'svelte';
	import { writable } from 'svelte/store';
	import { cubicOut } from 'svelte/easing';
	import { fly } from 'svelte/transition';
	import Navbar from '$lib/components/Navbar.svelte';
	import AppFooter from '$lib/components/footer.svelte';
	import '$lib/styles/main.css';
	const date = String(new Date().getFullYear());
	const answeredQuestions = writable();
	answeredQuestions.set({});
	setContext('answeredQuestions', answeredQuestions);
	export let data;
	let inAnimation = { y: 10, duration: 200, easing: cubicOut, delay: 200 };
	let outAnimation = { y: -10, duration: 200, easing: cubicOut };
</script>

<Navbar />
{#key data.pathname}
	<div in:fly={inAnimation} out:fly={outAnimation}>
		<slot />
	</div>
{/key}
<AppFooter />

<style>
	@import url('https://use.typekit.net/htu7yaj.css');
</style>
